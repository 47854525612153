import React from 'react';
import _ from 'lodash';
import { Button, Container, Row, Col } from 'reactstrap';
import Select from 'react-select';

import FundPickerHeader from '../../shared/FundPickerHeader';
import FundPerformanceBars from '../../shared/FundPerformanceBars';
import Portfolio from '../../shared/Portfolio';
import { formatMoney } from '../../shared/utils';

const fundEntities = {
  '645 Ventures, L.P.': 'Fund I',
  '645 Ventures II, L.P.': 'Fund II'
};

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    const funds = this.props.current_user.commitment_summary.map(summary => summary.fund_name);

    this.state = {
      name: this.props.name,
      activeCommitmentSummary: funds[0],
      commitmentOptions: funds,
      as_of: { label: this.props.current_quarter, value: this.props.current_quarter }
    };
  }

  funds() {
    return this.state.commitmentOptions.sort().reverse().map(key => fundEntities[key].replace(/Fund /, ''));
  }

  activeFund() {
    return this.fundFromEntity(this.state.activeCommitmentSummary);
  }

  setActiveEntityFromFund(fund) {
    this.setState({activeCommitmentSummary: this.entityFromFund(fund)});
  }

  fundFromEntity(entity) {
    return fundEntities[entity].replace('Fund ', '');
  }

  entityFromFund(fund) {
    const key = fund.indexOf('Fund') === 0 ? fund : 'Fund ' + fund;
    return _.findKey(fundEntities, v => v === key);
  }

  summaryForCommitment(summary) {
    return (
      <React.Fragment>
        <Row>
          <Col>
            <div className='commitment-summary-data'>
              <div className='value'>{formatMoney(summary.committed, 0)}</div>
              <span className='label'>Initial Commitment</span>
            </div>
          </Col>
          <Col>
            <div className='commitment-summary-data'>
              <div className='value'>{formatMoney(summary.last_capital_call_amount, 0)} on {summary.last_capital_call_date}</div>
              <span className='label'>Last Capital Call</span>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className='commitment-summary-data'>
              <div className='value'>{formatMoney(summary.contributions, 0)}</div>
              <span className='label'>Contribution To Date</span>
            </div>
          </Col>

          <Col>
            <div className='commitment-summary-data'>
              <div className='value'>{formatMoney(summary.distribution, 0)}</div>
              <span className='label'>Capital Returned</span>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className='commitment-summary-data'>
              <div className='value'>{formatMoney(summary.unfunded, 0)}</div>
              <span className='label'>Remaining Commitment</span>
            </div>
          </Col>

          <Col>
            <div className='commitment-summary-data'>
              <div className='value'>{formatMoney(summary.value, 0)}</div>
              <span className='label'>Capital Balance</span><br />
              <span className='subtitle'>As of {summary.as_of}</span>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  fundIDocuments() {
    if (this.props.current_user.fund_i_investor) {
      return (
        <div style={{marginBottom: '28px', marginTop: '18px'}}>
          <h4 className='fund-docs-title'>Fund I</h4>
          {this.props.current_user.fund_i_k1_present ?
            <div className='document-row'>
              <a href='/download_fund_i_k1' target='_blank'>
                <span className='label'>Latest Fund I K1<i className='fa fa-cloud-download' /></span>
              </a>
            </div> :''}

          {this.props.current_user.fund_i_capital_account_statement_present ?
            <div className='document-row'>
              <a href={`/download_fund_i_capital_account_statement?${$.param({entity: this.props.active_entity})}`} target='_blank'>
                <span className='label'>Latest Fund I Capital Account Statement<i className='fa fa-cloud-download' /></span>
              </a>
            </div> : ''}

          {!this.props.current_user.both_funds_investor ?
            <div className='document-row'>
              <a href={`/download_fund_i_quarterly_financial_report?${$.param({entity: this.props.active_entity})}`} target='_blank'>
                <span className='label'>Fund I Q3 2020 Report<i className='fa fa-cloud-download' /></span>
              </a>
            </div> : ''}

          <div className='document-row'>
            <a href='/download_fund_i_financials' target='_blank'>
              <span className='label'>Fund I Q3 2020 Financial Statements<i className='fa fa-cloud-download' /></span>
            </a>
          </div>

          <div className='document-row'>
            <a href='/download_fund_i_lpa' target='_blank'>
              <span className='label'>Fund I LPA<i className='fa fa-cloud-download' /></span>
            </a>
          </div>
        </div>
      );
    }
  }

  fundIIDocuments() {
    if (this.props.current_user.fund_ii_investor) {
      return (
        <React.Fragment>
          <h4 className='fund-docs-title'>Fund II</h4>
          {this.props.current_user.fund_ii_k1_present ?
            <div className='document-row'>
              <a href={`/download_fund_ii_k1?${$.param({entity: this.props.active_entity})}`} target='_blank'>
                <span className='label'>Latest Fund II K1<i className='fa fa-cloud-download' /></span>
              </a>
            </div> :''}

          {this.props.current_user.fund_ii_capital_account_statement_present ?
            <div className='document-row'>
              <a href={`/download_fund_ii_capital_account_statement?${$.param({entity: this.props.active_entity})}`} target='_blank'>
                <span className='label'>Latest Fund II Capital Account Statement<i className='fa fa-cloud-download' /></span>
              </a>
            </div> : ''}

          {this.props.current_user.fund_ii_capital_call_present ?
            <div className='document-row'>
              <a href={`/download_fund_ii_capital_call?${$.param({entity: this.props.active_entity})}`} target='_blank'>
                <span className='label'>Latest Fund II Capital Call<i className='fa fa-cloud-download' /></span>
              </a>
            </div> : ''}

          {!this.props.current_user.both_funds_investor ?
            <div className='document-row'>
              <a href={`/download_fund_ii_quarterly_financial_report?${$.param({entity: this.props.active_entity})}`} target='_blank'>
                <span className='label'>Fund II Q3 2020 Report<i className='fa fa-cloud-download' /></span>
              </a>
            </div> : ''}

          <div className='document-row'>
            <a href={`/download_fund_ii_financials?${$.param({entity: this.props.active_entity})}`} target='_blank'>
              <span className='label'>Fund II Q3 2020 Financial Statements<i className='fa fa-cloud-download' /></span>
            </a>
          </div>

          <div className='document-row'>
            <a href={`/download_fund_ii_lpa?${$.param({entity: this.props.active_entity})}`} target='_blank'>
              <span className='label'>Fund II LPA<i className='fa fa-cloud-download' /></span>
            </a>
          </div>
        </React.Fragment>
      );
    }
  }

  entitySelector() {
    if (this.props.available_entities.length > 1) {
      return (
        <Row>
          <Col md='12' lg='12' sm='12'>
            <span className='entity-choice-title'>Select entity to manage</span>
          </Col>
          <Col md='12' lg='12' sm='12'>
            <Select
              options={this.props.available_entities.map(ent => {
                return { value: ent, label: `View information for ${ent}` };
              })}
              value={{ value: this.props.active_entity, label: `Viewing information for ${this.props.active_entity}`}}
              onChange={(value) => window.location.href = `/?${$.param({active_entity: value.value})}` }
            />
          </Col>
        </Row>
      );
    }
  }

  render() {
    const summaries = this.props.current_user.commitment_summary.filter(comm => {
      return comm.fund_name == this.state.activeCommitmentSummary;
    });
    let summary = {};

    if (summaries.length > 0) {
      summary = summaries[0];
    }

    return (
      <Container className='dashboard-container' fluid>
        {this.entitySelector()}
        <Row>
          <Col sm='12'>
            <div className='overview-data-box'>
              <FundPerformanceBars user={this.props.current_user}
                fundIData={this.props.fund_i}
                fundIIData={this.props.fund_ii} 
                fundIIIData={this.props.fund_iii}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col sm='12'>
            <div className='overview-data-box commitment-box'>
              <FundPickerHeader
                funds={this.funds()}
                selected={this.activeFund()}
                title='Commitment Summary'
                onSelect={fund => this.setActiveEntityFromFund(fund)}
              />
              {this.summaryForCommitment(summary)}
            </div>
          </Col>
        </Row>

        <Row>
          <Col sm='12'>
            <div className='overview-data-box'>
              <Portfolio user={this.props.current_user} 
                fundIData={this.props.fund_i} 
                fundIIData={this.props.fund_ii}
                fundIIIData={this.props.fund_iii}
              />
            </div>
          </Col>
        </Row>

        <Row>

          <Col sm='12'>
            <div className='overview-data-box'>
              <h3 className='card-title'>Key Files</h3>

              {this.props.current_user.both_funds_investor ?
                <div className='document-row'>
                  <a href={`/download_fund_i_and_ii_quarterly_financial_report?${$.param({entity: this.props.active_entity})}`} target='_blank'>
                    <span className='label'>Fund I & Fund II Q3 Report<i className='fa fa-cloud-download' /></span>
                  </a>
                </div> : ''}

              {this.fundIDocuments()}
              {this.fundIIDocuments()}

              <a href='https://645ventures.arkpes.com/' target='_blank'>
                <Button className='ark-button'color='success'>Login to 645 Reporting Portal</Button>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}
