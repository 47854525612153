import React from 'react';
import YouTube from 'react-youtube';

import { Container, Row, Col } from 'reactstrap';

const youtubeOptions = {
  width: '100%',
  height: '280',
  onEnd: event => event.target.resetPlayer(),
  playerVars: {
    controls: 1,
    fs: 0,
    modestbranding: 1,
    rel: 0
  }
};

export default class AnnualMeeting extends React.Component {
  constructor(props) {
    super(props);
  }

  video(id, title, speaker, description) {
    return (
      <Col md='4' className='meeting-clip'>
        <YouTube
          videoId={id}
          opts={youtubeOptions}
        />
        <div className='details'>
          <h4>{title}</h4>
          <h5>{speaker}</h5>
          {event
            ? <p>{description}</p>
            : ''
          }
        </div>
      </Col>
    );
  }

  render() {
    return (
      <Container className='dashboard-container annual-meeting-container' fluid>
        <Row>
          <Col>
            <div className='overview-data-box'>
              <h3 className='card-title'>2022 Annual Meeting Videos</h3>

              <Row>
                {this.video(
                  '_qG8O6zBfpM'
                  , '2022 Annual Meeting: Fund Strategy & Performance Presentation' 
                  , 'Aaron Holiday & Nnamdi Okike'
                )}
                {this.video(
                  'nPm-sIq5Nyc'
                  , '2022 Annual Meeting: Portfolio Spotlight: Condor'
                )}
                {this.video(
                  '-GanRE7Pe4Q'
                  , '2022 Annual Meeting: Portfolio Spotlight: Shift5'
                )}
              </Row>

              <Row>
                {this.video(
                  'I574IekWzuY'
                  , '2022 Annual Meeting: Investing in Crypto & Web3'
                )}
                {this.video(
                  '1lW6KcXOqkI'
                  , '2022 Annual Meeting: Building in Crypto & Web3'
                )}
              </Row>

              <h3 className='card-title'>Previous Annual Meetings Video Clips</h3>

              <Row>
                {this.video(
                  'UBGnCSSO1ZY'
                  , '2021 Annual Meeting: Fund Strategy & Performance Presentation' 
                  , 'Aaron Holiday & Nnamdi Okike'
                )}
                {this.video(
                  'NhrUFIrZpp4'
                  , '2021 Annual Meeting: Portfolio Spotlights'
                  , 'Joe Ariel (GoldBelly) & Songe LaRon (Squire) & Matt McCambridge (Eden Health)'
                )}
                {this.video(
                  'n-guf2nNnIo'
                  , '2021 Annual Meeting: Fireside Chat with Reid Hoffman'
                  , 'Nnamdi Okike'
                )}
              </Row>

              <Row>
                {this.video(
                  'JFzxeaWx0EQ'
                  , '2020 Annual Meeting: Fund Strategy & Performance Presentation'
                  , 'Aaron Holiday & Nnamdi Okike'
                )}
                {this.video(
                  'xUTz53UTjKM'
                  , '2020 Annual Meeting: Scott Kupor and Aaron Holiday Fireside Chat'
                  , 'Aaron Holiday'
                )}
                {this.video(
                  'mpb4C7msmW4'
                  , '2020 Annual Meeting: Roger Ferguson and Nnamdi Okike Fireside Chat'
                  , 'Nnamdi Okike'
                )}
              </Row>

              <Row>
                {this.video(
                  'LheCwKJjdCE'
                  , '2019 Annual Meeting: Fund Strategy & Performance Presentation'
                  , 'Aaron Holiday, Nnamdi Okike'
                  , '645 Ventures strategy for large multi-stage funds that are coming downstream'
                )}

                {this.video(
                  'wlYYvtmH9y4'
                  , '2019 Annual Meeting: Fireside Chat with Andy Golden'
                  , 'Aaron Holiday'
                  , 'Andy Golden on finding people who the world roots for'
                )}
                {this.video(
                  'DCbz_wqPCPM'
                  , '2018 Annual Meeting: Fireside Chat with Ken Chenault'
                  , 'Nnamdi Okike'
                  , 'Ken Chenault on 645 and the growth of our firm'
                )}
              </Row>

              <Row>
                {this.video(
                  'hqQakyLnIww'
                  , '2017 Annual Meeting: Fund Strategy & Performance Presentation'
                  , 'Aaron Holiday'
                  , 'Origin story of our outbound deal sourcing model, powered by proprietary software and networks'
                )}
                {this.video(
                  'k1fFJpmHh8E'
                  , '2017 Annual Meeting: Fund Strategy & Performance Presentation'
                  , 'Nnamdi Okike'
                  , 'Explaining the software that powers our operation'
                )}
                {this.video(
                  'ltw2pfICG5E'
                  , '2017 Annual Meeting: Fund Strategy & Performance Presentation'
                  , 'Nnamdi Okike'
                  , 'Goldbelly investment case study'
                )}
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}
