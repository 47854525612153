import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Row, Col } from 'reactstrap';
import ReactTooltip from 'react-tooltip';

import FundPickerHeader from './FundPickerHeader';
import { toMillion, colorGradient } from './utils';
import Globals from './globals';

import 'chartjs-plugin-datalabels';
import 'jquery';
import 'tablesorter/dist/js/jquery.tablesorter.min.js';


export default class Portfolio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFund: 'I'
    };
  }

  chartOptions(labelMaker) {
    return {
      responsive: false,
      tooltips: {
        mode: 'index',
        callbacks: {
          label: function(tooltipItem, data) {
            const amount = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            const label = data.labels[tooltipItem.index];
            return labelMaker(amount, label);
          },
        },
        footerFontStyle: 'normal'
      },
      legend: {
        display: false,
        position: 'left',
        labels: {
          boxWidth: 15,
          fontFamily: Globals.legendFont,
          fontColor: '#242946'
        }
      },
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        }
      },
      plugins: {
        datalabels: {
          formatter: value => `${value}%`,
          display: function(context) {
            return context.dataset.data[context.dataIndex] >= 10; // or >= 1 or ...
          },
          clamp: true,
          color: 'white',
          font: {
            weight: 700,
            family: 'Urbanist',
            size: 18,
          }
        }
      }
    };
  }

  fundButtonHeader(title) {
    if (this.props.user.funds_invested.length == 1) {
      if (this.state.selectedFund != this.props.user.funds_invested) {
        this.setState({selectedFund: this.props.user.funds_invested});
      }
    } else {
      return (
        <div className='fund-button-header'>
          {this.props.user.funds_invested.map(fund => {
            let classes = ['fund-button'];
            if (this.state.selectedFund === fund) classes.push('fund-button-active');
            return (
              <div
                key={fund}
                className={classes.join(' ')}
                onClick={() => this.setState({selectedFund: fund})}
              >
                Fund {fund}
              </div>
            );
          })}
          <h3>{title}</h3>
        </div>
      );
    }
  }

  investmentHighlight(investment) {
    return (
      <div className='investment-highlight'>
        <h3>
          {investment.link
            ? <a href={investment.link} target="_blank">{investment.name}</a>
            : <span>{investment.name}</span>
          }
        </h3>
        <h4>{investment.label}</h4>
        <p>{investment.investment}</p>
        <p>{investment.subtitle}</p>
        <p>{investment.first_comment}</p>
        <p>{investment.second_comment}</p>
      </div>
    );
  }

  renderStats(data) {
    return (
      <React.Fragment>
        <Row className='portfolio-stats'>
          <Col sm='4'>
            <div className='bundle'>
              <div>
                <div className='value'>{data.companies || 0}</div>
                <div className='label'>Companies</div>
              </div>
              <div>
                <div className='value'>${data.fund_size || 0}M</div>
                <div className='label'>Fund Size</div>
              </div>
              <div>
                <div className='value'>{toMillion(data.carrying_value || 0, 1)}</div>
                <div className='label'>Portfolio Value</div>
              </div>
            </div>
          </Col>
          <Col sm='4'>
            <div className='bundle'>
              <div>
                <div className='value'>{toMillion(data.called_capital || 0, 1)}</div>
                <div className='label'>Capital Called</div>
              </div>
              <div>
                <div className='value'>{toMillion(data.invested_capital || 0, 1)}</div>
                <div className='label'>Capital Invested</div>
              </div>
              <div>
                <div className='value'>{toMillion(data.allocated_capital || 0, 1)}</div>
                <div className='label'>Capital Allocated*</div>
              </div>
            </div>
          </Col>
          <Col sm='4'>
            <div className='bundle'>
              <div>
                <div className='value'>{toMillion(data.median_check_size || 0, 1)}</div>
                <div className='label'>Median Check Size</div>
              </div>
              <div>
                <div className='value'>{data.median_ownership || 0}%</div>
                <div className='label'>Median Ownership</div>
              </div>
              <div>
                <div className='value'>{data.board_presence || 0}</div>
                <div className='label'>Board Presence</div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className='aside'>
          <Col sm='12'>
            <p>* Capital Allocated assumes 100% recycling</p>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  renderBreakdown(data) {
    return (
      <Row className='portfolio-breakdown'>
        <Col sm='4'>
          <h3>Capital Deployed</h3>
          <h4>At entry by stage</h4>
          <Pie
            height={150}
            width={150}
            data={{
              labels: ['Seed', 'Growth Seed', 'Series A', 'Series B+'],
              datasets: [{
                label: 'Capital Deployed',
                data: [data.seed_entry, data.growth_seed_entry, data.series_a_entry, data.series_bplus_entry],
                backgroundColor: colorGradient('#ff0000', '#800000', 4).reverse(),
                borderWidth: 0
              }]
            }}
            options={this.chartOptions((amount, label) => `${amount}%: ${label}`)}
          />
        </Col>
        <Col sm='4'>
          <h3>Capital Deployed</h3>
          <h4>Total by stage</h4>
          <Pie
            height={150}
            width={150}
            data={{
              labels: ['Seed', 'Growth Seed', 'Series A', 'Series B+'],
              datasets: [{
                label: 'Capital Deployed',
                data: [data.seed_overall, data.growth_seed_overall, data.series_a_overall, data.series_bplus_overall],
                backgroundColor: colorGradient('#ff8700', '#c06400', 4).reverse(),
                borderWidth: 0
              }]
            }}
            options={this.chartOptions((amount, label) => `${amount}%: ${label}`)}
          />
        </Col>
        <Col sm='4'>
          <h3>Deals Sectors</h3>
          <h4>-</h4>
          <Pie
            height={150}
            width={150}
            data={{
              labels: ['SaaS', 'Consumer', 'Infrastructure & Security'],
              datasets: [{
                label: 'Deal Sectors',
                data: [data.saas_percentage, data.consumer_percentage, data.infrastructure_percentage],
                backgroundColor: ['#f9af21', '#e09c1d', '#bb8216'],
                borderWidth: 0
              }]
            }}
            options={this.chartOptions((amount, label) => `${amount}%: ${label}`)}
          />
        </Col>
      </Row>
    );
  }

  renderCaseStudies(data) {
    return (
      <Row className='portfolio-case-studies'>
        <Col sm='12'>
          <h3>Case Studies</h3>
          <h4>Breakout Winners & New Investments</h4>
        </Col>
        {data.investments
          ? <Col sm='12'>
            <Row>
              {data.investments.case_studies.map((investment, idx) => (
                <Col sm='4' key={idx}>
                  {this.investmentHighlight(investment, investment.label)}
                </Col>
              ))}
            </Row>
          </Col>
          : ''}
      </Row>
    );
  }

  renderFollowOns(data) {
    return data.coinvestors
      ? <Row className='portfolio-follow-ons'>
        <Col sm='12'>
          <h3>Top Follow On and Co-Investors</h3>

          <div className="co-investor-logos">
            {(data.coinvestors.investors ? data.coinvestors.investors : []).map((coInvestor, idx) => (
              <img key={idx}
                alt={`${coInvestor.slug}.png`}
                className="co-investor-logo"
                src={`/images/investor-logos/${coInvestor.slug}.png`}
                data-tip={`${coInvestor.name}: co-investor in ${coInvestor.companies}`}
                currentitem="false" />
            ))}
          </div>
        </Col>
      </Row>
      : ''
    ;
  }

  renderFundData(data) {
    data = data || {};
    return (
      <div className='portfolio'>
        {this.renderStats(data)}
        {this.renderBreakdown(data)}
        {this.renderCaseStudies(data)}
        {this.renderFollowOns(data)}
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <FundPickerHeader
          funds={this.props.user.funds_invested}
          selected={this.state.selectedFund}
          title='Portfolio Summary'
          onSelect={fund => this.setState({selectedFund: fund})}
        />

        {this.renderFundData(this.props[`fund${this.state.selectedFund}Data`])}

        <ReactTooltip effect='solid' />
      </React.Fragment>
    );
  }
}
