import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Row, Col } from 'reactstrap';
import 'chartjs-plugin-datalabels';

import FundPickerHeader from './FundPickerHeader';
import Globals from './globals';
import { formatMultiplier, formatPercent } from './utils';

const chartOptions = tickLabeler => {
  return {
    tooltips: {
      callbacks: {
        label: ctx => tickLabeler(ctx.yLabel, 1)
      }
    },
    legend: {
      display: false
    },
    layout: {
      padding: {
        left: 40,
        right: 40,
        top: 20, // This is weirdly necessary - data labels get hidden when set to 0
        bottom: 0
      }
    },
    plugins: {
      datalabels: {
        formatter: v => tickLabeler(v, 1),
        display: true,
        align: 'end',
        anchor: 'end',
        offset: 1,
        precision: 2,
        clamp: true,
        color: 'black',
        font: {
          weight: 400,
          family: 'Urbanist',
          size: 14
        }
      }
    },
    scales: {
      xAxes: [{
        categoryPercentage: 1.0,
        barPercentage: 0.95,
        gridLines: { display: false },
        ticks: {
          display: false,
          fontSize: 0,
        }
      }],
      yAxes: [{
        gridLines: { display: false },
        ticks: { display: false, beginAtZero: true }
      }],
    }
  };
};

export default class FundPerformanceBars extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFund: 'I'
    };
  }

  fundChartData() {
    return {
      i: {
        tvpi:      {top_5: 8.2,    uq: 4.4,  fund: this.props.fundIData.fund_tvpi}
        , dpi:     {top_5: 2.9,  uq: 1.1,    fund: this.props.fundIData.fund_dpi}
        , net_irr: {top_5: 49.6, uq: 33.6, fund: this.props.fundIData.fund_net_irr}
        , benchmark_date: 'December 2021'
      }
      , ii: {
        tvpi:      {top_5: 4,   uq: 2.3,    fund: this.props.fundIIData.fund_tvpi}
        , dpi:     {top_5: 0.5,  uq: 0.07, fund: this.props.fundIIData.fund_dpi}
        , net_irr: {top_5: 98.0, uq: 55.7, fund: this.props.fundIIData.fund_net_irr}
        , benchmark_date: 'December 2021'
      }
      , iii: {}
    };
  }

  getChartData() {
    return this.fundChartData()[this.state.selectedFund.toLowerCase()];
  }

  getFundData() {
    return this.props['fund' + this.state.selectedFund + 'Data'];
  }

  performanceData(fund, chart) {
    if (Object.keys(chart).length === 0) {
      return (
        <div className="subtitle">
          Cambridge Associates hasn't yet released 2021 vintage data. <br />
          We will update this once it's made available to us.
        </div>
      );
    }

    return (
      <Row>
        {this.chart('TVPI',    [chart.tvpi.top_5,    chart.tvpi.uq,    chart.tvpi.fund],    formatMultiplier)}
        {this.chart('DPI',     [chart.dpi.top_5,     chart.dpi.uq,     chart.dpi.fund],     formatMultiplier)}
        {this.chart('Net IRR', [chart.net_irr.top_5, chart.net_irr.uq, chart.net_irr.fund], formatPercent)}

        <Row className='charts-legend'>
          <Col sm='3'>
            <div className='legend-box' style={{backgroundColor: Globals.chartColors.bar[0]}} />
            <div className='legend-label'>Top 5%</div>
          </Col>

          <Col sm='3'>
            <div className='legend-box' style={{backgroundColor: Globals.chartColors.bar[1]}} />
            <div className='legend-label'>Top Quartile</div>
          </Col>

          <Col sm='3'>
            <div className='legend-box' style={{backgroundColor: Globals.chartColors.bar[2]}} />
            <div className='legend-label'>645 {fund.fund_name}</div>
          </Col>
          <Col className='vintage' sm='3'>
            <span>{fund.fund_vintage} Vintage</span>
          </Col>
        </Row>
      </Row>
    );
  }

  render() {
    const chart= this.getChartData()
      , fund = this.getFundData()
    ;

    return (
      <React.Fragment>
        <Row>
          <Col sm='12'>
            <FundPickerHeader
              funds={this.props.user.funds_invested}
              selected={this.state.selectedFund}
              title='Industry Benchmarks'
              onSelect={fund => this.setState({selectedFund: fund})}
            />
          </Col>
        </Row>

        {this.performanceData(fund, chart)}

        <Row className='aside'>
          <Col sm='12'>
            <p>Cambridge Benchmarks as of {chart.benchmark_date}</p>
            <p>This data was provided "as is" and at no cost by Cambridge Associates LLC.</p>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  chart(label, data, formatter) {
    return (
      <Col md='4' lg='4' sm='12'>
        <Bar
          height={180}
          data={{
            labels: ['Top 5%', 'Top Quartile', `645 Fund ${this.state.selectedFund}`],
            datasets: [{
              label: label,
              data: data,
              backgroundColor: Globals.chartColors.bar
            }]
          }}

          options={chartOptions(formatter)}
        />

        <h3 className='chart-title'>{label}</h3>
      </Col>
    );
  }
}
