import React from 'react';
import _ from 'lodash';
import { Container, Row, Col } from 'reactstrap';

import FundPerformanceBars from '../../shared/FundPerformanceBars';
import FundComparison from '../../shared/FundComparison';
import Portfolio from '../../shared/Portfolio';

export default class DataRoom extends React.Component {
  constructor(props) {
    super(props);

    this.funds = _(this.props.funds)
      .sortBy(['fund_name'])
      .keyBy(fund => _.snakeCase(fund.fund_name))
      .value()
    ;
    this.copy = _.reduce(props.copy, (obj, copy) => _.set(obj, copy.slug, copy.copy), {});
    this.state = { name: this.props.name };
  }

  render() {
    // Note that the old download link is /download_document?document_id=114
    // This should be reinstated ASAP
    return (
      <Container className='dashboard-container' fluid>
        <Row>
          <Col sm='12'>
            <div className='overview-data-box'>
              <h3 className='card-title'>645 Toolkit</h3>
              <p className='page-description'>{this.copy.downloader_description}</p>
              <div className='download-action-buttons'>
                <a href='/data_room/downloads' style={{marginTop: '18px'}} target='_blank' className='btn btn-secondary'>
                  Browse Individual Files
                </a>
                <a href='https://645ventures-public-experiment-staging.s3.amazonaws.com/lps/645+Ventures+Fund+IV+Data+Room.zip' style={{marginTop: '18px'}} target='_blank' className='btn btn-success'>
                  Download ZIP File
                </a>
              </div>
            </div>
          </Col>

          <Col sm='12'>
            <div className='overview-data-box'>
              <FundPerformanceBars fundIData={this.funds.fund_i} fundIIData={this.funds.fund_ii} fundIIIData={this.funds.fund_iii} user={{ funds_invested: ['I', 'II', 'III']}} />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className='overview-data-box'>
              <Portfolio fundIData={this.funds.fund_i} fundIIData={this.funds.fund_ii} fundIIIData={this.funds.fund_iii} investments={this.props.investments} user={{ funds_invested: ['I', 'II', 'III']}} forDataRoom />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className='overview-data-box'>
              <FundComparison funds={this.funds} data={this.props.fund_comparison} user={{ funds_invested: ['I', 'II', 'III']}} />
            </div>
          </Col>
        </Row>

        {Object.keys(this.props.references).map((referenceType) => {
          return (
            <Row key={referenceType}>
              <Col>
                <div className='overview-data-box'>
                  <h3 className='references-title'>{referenceType} References</h3>

                  <table className='table-light tablesorter'>
                    <thead>
                      <tr>
                        <th style={{width: '20%'}}>Name</th>
                        <th style={{width: '30%'}}>Role</th>
                        <th style={{width: '30%'}}>Affiliation</th>
                        <th style={{width: '20%'}}>Reference For</th>
                      </tr>
                    </thead>

                    <tbody>
                      {this.props.references[referenceType].map((reference) => {
                        return (
                          <tr className='reference-row' key={reference.name}>
                            <td>{reference.name}</td>
                            <td>{reference.position}, {reference.company}</td>
                            <td>{reference.affiliation}</td>
                            <td>{reference.referenceFor}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </Col>
            </Row>
          );
        })}
      </Container>
    );
  }
}
