import React from 'react';

export default class FundPickerHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // This can be 2 things - older controls with hard-coded fund lists will pass [I,II,III]
      // Newer controls will pass an actual array of fund objects
      funds: this.props.funds
      // Same here - older will be [I,II], newer will be [firstId, secondId]
      , selected: this.getSelectedState(this.props.selected)
      , onSelect: this.props.onSelect
      , title: this.props.title || ''
      , multiselect: Boolean(this.props.multiselect)
    };
  }

  getSelectedState(val, fallback) {
    if (val instanceof Array) return val;
    if (!val) return fallback ? [fallback] : [];
    return [val];
  }

  componentDidUpdate(prevProps) {
    const oldSelected = this.getSelectedState(prevProps.selected)
      , newSelected = this.getSelectedState(this.props.selected)
      , changed = (
        // Changed if there's a different # selected
        newSelected.length !== oldSelected.length
        // Changed if something new doesn't exist in the old
        || newSelected.reduce((res, val) => {
          // Move on if we're already sure it changed
          if (res) return res;
          // See if a current selected already exists
          return oldSelected.indexOf(val) < 0;
        }, false)
      )
    ;
    if (changed) this.setState({selected: newSelected});
  }

  onSelect(fund) {
    return this.state.multiselect
      ? this.onMultiselect(fund)
      : this.state.onSelect(fund)
    ;
  }

  onMultiselect(fund) {
    const newSelected = this.state.selected.slice()
      , foundAt = newSelected.indexOf(fund)
    ;
    if (foundAt >= 0) {
      newSelected.splice(foundAt, 1);
    } else {
      newSelected.push(fund);
      newSelected.sort();
    }
    this.state.onSelect(newSelected);
  }

  render() {
    return (
      <div className='fund-picker-header'>
        {this.state.funds.map(fund => {
          let classes = ['fund-button'];
          if (this.state.selected.indexOf(fund.id || fund) >= 0) classes.push('fund-button-active');
          return (
            <div
              key={fund.id || fund}
              className={classes.join(' ')}
              onClick={() => this.onSelect(fund.id || fund)}
            >
              {fund.fund_name || 'Fund ' + fund}
            </div>
          );
        })}
        <h2>{this.state.title}</h2>
      </div>
    );
  }
}
