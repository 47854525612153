import ReactOnRails from 'react-on-rails';

import DataRoom from '../bundles/DataRoom/components/DataRoom';
import Dashboard from '../bundles/Dashboard/components/Dashboard';
import AnnualMeeting from '../bundles/AnnualMeeting/components/AnnualMeeting';

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  DataRoom,
  Dashboard,
  AnnualMeeting
});
