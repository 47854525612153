import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Row, Col } from 'reactstrap';
import _ from 'lodash';

import FundPickerHeader from './FundPickerHeader';
import {toMillion, formatMoney} from './utils';

const datasetDefaults = {
    lineTension: 0
    , fill: false
    , pointStyle: 'circle'
    , pointRadius: 5
    , datalabels: {display: false}
  }
  , colors = ['#f9af21', '#ff8700', '#ff0000']
;

function chartOptions(tickLabeler) {
  return {
    tooltips: {
      callbacks: {
        label: item => tickLabeler(item.yLabel)
      }
    }
    , stacked: false
    , spanGaps: true
    , legend: {
      position: 'bottom'
    }
    , layout: {
      padding: {
        top: 20 // This is weirdly necessary - data labels get hidden when set to 0
        , right: 10
      }
    }
    , scales: {
      xAxes: [{
      }],
      yAxes: [{
        ticks: {
          callback: tickLabeler 
          , beginAtZero: true
        }
      }],
    }
    , plugins: {
      datalabels: {
        display: false
      }
    }
  };
}

export default class FundComparison extends React.Component {
  constructor(props) {
    super(props);
    // We'll organize the incoming data into per-chart buckets. First we set up the buckets...
    this.chartData = _(this.props.data.meta).reduce((obj, meta) => {
      obj[meta.id] = {
        records: _.reduce(this.props.funds, (obj, fund) => {
          // Make sure we don't have nulls for any of the funds
          obj[fund.id] = {};
          return obj;
        }, {})
        , meta
      };
      return obj;
    }, {});
    // ...then we shovce the data into the right bucket.
    _(this.props.data.records).each(record => {
      this.chartData[record.fund_comparison_id].records[record.fund_id] = record;
    });
    this.state = {
      selectedFunds: _.map(this.props.funds, fund => fund.id)
      , selectedComparison: this.props.data.meta[0].id
    };
  }

  getFundData(fundId) {
    return _.find(this.props.funds, {id: fundId});
  }

  picker() {
    return (
      <React.Fragment>
        {_.map(this.chartData, (chart, id) => {
          return (
            <div key={id} onClick={e => {
              if (e.target.tagName !== 'DIV') return;
              this.setState({selectedComparison: e.target.getElementsByTagName('input')[0].value});
            }}>
              <label>
                <input type='radio' name='fund-comparison-picker' value={id}
                  checked={Number(id) === Number(this.state.selectedComparison)}
                  onChange={e => this.setState({selectedComparison: e.target.value})} />
                <span>{chart.meta.name}</span>
              </label>
            </div>
          );
        })}
      </React.Fragment>
    );
  }

  tick(type, value) {
    switch(type) {
      case 'million_dollars':
        return toMillion(value);
      case 'percent':
        return `${value}%`;
      case 'dollars':
        return formatMoney(value, 0);
    }
    return value;
  }

  chart() {
    const chart = this.chartData[this.state.selectedComparison]
      , datasets = _(chart.records)
        .sortBy(record => _.find(this.props.funds, {id: Number(record.fund_id)}).fund_name)
        .reduce((filtered, record) => {
          const fundId = Number(record.fund_id);
          // Make sure this fund has been selected by the user
          if (this.state.selectedFunds.indexOf(fundId) < 0) return filtered;
          // Turn the data into a chartjs dataset
          filtered.push(_.extend({}, datasetDefaults, {
            label: this.getFundData(fundId).fund_name 
            , data: [
              {x: 0, y: record.val_1}
              , {x: 1, y: record.val_2}
              , {x: 2, y: record.val_3}
            ]
            , backgroundColor: colors[filtered.length]
            , pointBackgroundColor: colors[filtered.length]
          }));
          return filtered;
        }, [])
    ;
    return (
      <Bar
        data={{
          labels: [chart.meta.bucket_1, chart.meta.bucket_2, chart.meta.bucket_3]
          , datasets: datasets
        }}
        options={chartOptions(v => this.tick(chart.meta.value_type, v))}
      />
    );
  }

  render() {
    return (
      <React.Fragment>
        <FundPickerHeader
          funds={Object.values(this.props.funds)}
          selected={this.state.selectedFunds}
          title='Fund Comparison'
          multiselect={true}
          onSelect={funds => this.setState({selectedFunds: funds})}
        />

        <Row>
          <Col sm={4} className='comparison-picker'>
            {this.picker()}
            <hr />
            <p>{this.chartData[this.state.selectedComparison].meta.description}</p>
          </Col>
          <Col sm={8} className='comparison-chart'>
            {this.chart()}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
