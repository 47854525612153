import _ from 'lodash';

export function formatNumber(amount, digits) {
  // Note: If we decide to only support ie11+, this can be replaced by:
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
  if (!_.isNumber(digits)) digits = 2;

  const inFloat = parseFloat(String(amount).replace(/,/g, '')).toFixed(digits);
  if (isNaN(inFloat)) return amount;

  let parts = String(inFloat).split('.');
  parts[0] = _(parts[0].split('')).reduceRight((out, v) => {
    // out[0] = the triplet we're working on, out[1] is the string we've built
    out[0] = v + out[0];
    if (out[0].length === 3) {
      out[1] = ',' + out[0] + out[1];
      out[0] = '';
    }
    return out;
  }, ['', '']).join('').replace(/^,/, '');
  if (digits > 0) {
    if (parts.length === 1) {
      parts.push('');
    }
    parts[1] = _.padEnd(parts[1], digits, '0');
  } else {
    parts = [parts[0]];
  }

  return parts.join('.');
}

export function formatMoney(amount, digits) {
  return `$${formatNumber(amount, digits)}`;
}

export function formatPercent(amount, digits) {
  return `${formatNumber(amount, digits)}%`;
}

export function formatMultiplier(amount, digits) {
  return `${formatNumber(amount, digits)}X`;
}

export function toMillion(numString, digits) {
  const i = Number(String(numString).replace(/\$/g, '').replace(/,/g, ''))
    , millions = Number(i) / 1000000
  ;
  return `${formatMoney(millions, digits)}M`;
}

function colorStepSize(color1, color2, steps) {
  return Math.floor((color1 - color2) / steps);
}
function hexToRgb(hex) {
  return hex.replace(/^#/, '').split(/(.{2})/).filter(v => v != '').map(v => parseInt(v, 16));
}

export function colorGradient(lightest, darkest, steps) {
  // Make sure we're dealing with RGB values, ex: (128, 64, 32)
  if (typeof lightest === 'string') lightest = hexToRgb(lightest);
  if (typeof darkest === 'string') darkest = hexToRgb(darkest);

  // Figure out what kind of increment we make per step, ex: 4, 100, -45
  const [stepR, stepG, stepB] = [0,1,2].map(idx => colorStepSize(darkest[idx], lightest[idx], steps));

  // Actually make our list of RGB values
  let colors = [];
  _.times(steps, step => {
    const stepped = [lightest[0] + (stepR * step), lightest[1] + (stepG * step), lightest[2] + (stepB * step)];
    colors.push(stepped.map(val => {
      if (val < 0) return 0;
      if (val > 255) return 255;
      return val;
    }));
  });

  // Turn it back into the hex most style tools support
  return colors
    .map(colorRGB => '#' + colorRGB.map(v => _.padStart(Number(v).toString(16), 2, '0')).join(''))
  ;
}
colorGradient.defaultLightest = '#FFC268';
colorGradient.defaultDarkest = '#FF0000';
